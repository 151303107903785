import React from 'react';
import { Tag } from 'antd';
import classes from './tags.module.css';
import PropTypes from 'prop-types';

/* --------------------- Delivery --------------------- */

export const TagDelivery = () => {
    return <Tag className={classes.TagDelivery}>Delivery</Tag>;
};

export const TagPickUp = () => {
    return <Tag className={classes.TagPickUp}>Pick up</Tag>;
};

/* --------------------- Payment --------------------- */

export const TagPos = () => {
    return <h1 className={classes.Pos}>POS</h1>;
};

export const TagEfectivo = () => {
    return <h1 className={classes.Efectivo}>EFECTIVO</h1>;
};

export const TagQR = ({ codigoPagoOnline }) => {
    return (
        <h1 className={classes.Bancard}>
            Bancard QR:
            <span className={classes.Subtitle}>{codigoPagoOnline}</span>
        </h1>
    );
};

export const TagBepsa = () => {
    return <h1 className={classes.Bancard}>BEPSA</h1>;
};

export const TagTiendaNaranja = ({ codigoPagoOnline }) => {
    let componente = <></>;

    if (codigoPagoOnline) {
        let codigo;

        if (codigoPagoOnline?.length > 1) {
            codigo = codigoPagoOnline[1];
        } else {
            codigo = codigoPagoOnline[0];
        }

        componente = (
            <h1 className={classes.Bancard}>
                ITAÚ PAGOS:
                <span className={classes.Subtitle}>
                    {codigo.length && ` ${codigo}`}
                </span>
            </h1>
        );
    }

    return componente;
};

export const TagBancard = ({ codigoPagoOnline }) => {
    let componente = <></>;

    if (codigoPagoOnline) {
        let codigo;

        if (codigoPagoOnline?.length > 1) {
            codigo = codigoPagoOnline[1];
        } else {
            codigo = codigoPagoOnline[0];
        }

        componente = (
            <h1 className={classes.Bancard}>
                BANCARD:
                <span className={classes.Subtitle}>
                    {codigo.length && ` ${codigo}`}
                </span>
            </h1>
        );
    }

    return componente;
};

export const TagTigoMoney = ({ codigoPagoOnline }) => {
    let componente = <></>;

    if (codigoPagoOnline) {
        let codigo;

        if (codigoPagoOnline?.length > 1) {
            codigo = codigoPagoOnline[1];
        } else {
            codigo = codigoPagoOnline[0];
        }

        componente = (
            <h1 className={classes.Bancard}>
                TigoMoney:
                <span className={classes.Subtitle}>
                    {codigo.length && ` ${codigo}`}
                </span>
            </h1>
        );
    }

    return componente;
};

export const TagPix = ({ codigoPagoOnline }) => {
    if (codigoPagoOnline) {
        const code =
            codigoPagoOnline.length > 1
                ? codigoPagoOnline[1]
                : codigoPagoOnline[0];

        return (
            <h1 className={classes.Bancard}>
                PIX:
                <span className={classes.Subtitle}>
                    {code.length && ` ${code}`}
                </span>
            </h1>
        );
    }

    return <></>;
};

/* --------------------- Levels --------------------- */

export const MonchisRed = () => {
    return <Tag color="red">Monchis Red</Tag>;
};

export const MonchisSilver = () => {
    return (
        <Tag style={{ backgroundColor: '#d6d6d6', color: '#333' }}>
            Monchis Silver
        </Tag>
    );
};

export const MonchisGold = () => {
    return <Tag color="gold">Monchis Gold</Tag>;
};

export const MonchisBlack = () => {
    return <Tag color="black">Monchis Black</Tag>;
};

export const Silver = () => {
    return (
        <Tag style={{ backgroundColor: '#d6d6d6', color: '#333' }}>Silver</Tag>
    );
};

export const Gold = () => {
    return <Tag color="gold">Gold</Tag>;
};

export const Black = () => {
    return <Tag color="black">Black</Tag>;
};

TagTiendaNaranja.propTypes = {
    codigoPagoOnline: PropTypes.any,
};

TagBancard.propTypes = {
    codigoPagoOnline: PropTypes.any,
};

TagTigoMoney.propTypes = {
    codigoPagoOnline: PropTypes.any,
};

TagQR.propTypes = {
    codigoPagoOnline: PropTypes.any,
};
