import React, { useState, useEffect, useContext } from 'react';
import {
    Button,
    Col,
    Row,
    Card,
    Modal,
    Input,
    Select,
    Alert,
    Form,
    message,
    DatePicker,
    Spin,
} from 'antd';
import clases from './cuenta.module.css';
import clienteAxios from '../../../../../config/axios';
import { urls } from '../../../../../constants';
import {
    dateDDMMYYYY,
    dateDMYyHora,
    formatoFechaInput,
} from '../../../../utils-components/Date';
import UsuariosContext from '../../../../../context/usuario/UsuariosContext';
import moment from 'moment';

const CuentaUsuario = () => {
    // const dateFormat = 'YYYY-MM-DD';
    const [resetPassForm] = Form.useForm();
    const [editInfoForm] = Form.useForm();
    const { usuarioSeleccionado, infoUsuario, guardarInfoUsuario } =
        useContext(UsuariosContext);

    //controla el modal
    const [openModalInfoUser, setOpenModalInfoUser] = useState(false);
    const [editarInfo, setEditarInfo] = useState();
    const [datosNewPassword, setDatosNewPassword] = useState();
    const [nuevosDatosUsuario, setNuevosDatosUsuario] = useState();
    const [cargandoDatos, setCargandoDatos] = useState(false);

    useEffect(() => {
        getDataUser();
    }, []);

    useEffect(() => {
        if (datosNewPassword) {
            resetPasswordHandler();
        }
    }, [datosNewPassword]);

    useEffect(() => {
        if (nuevosDatosUsuario) {
            editInfoHandler();
        }
    }, [nuevosDatosUsuario]);

    // RESETEAR CONTRASEÑA

    const dataNewPassword = () => {
        resetPassForm
            .validateFields()
            .then(async () => {
                try {
                    let formData = resetPassForm.getFieldValue();
                    const contraseña = formData.contraseña;
                    const repetirContraseña = formData.repetirContraseña;
                    const data = {
                        password: contraseña,
                        passwordR: repetirContraseña,
                    };
                    setDatosNewPassword(data);
                } catch (err) {
                    console.log(err);
                }
            })
            .catch(() => {});
    };

    const generoUsuario = () =>
        infoUsuario?.gender == 'M' ? (
            <p>Masculino</p>
        ) : infoUsuario?.gender == 'F' ? (
            <p>Femenino</p>
        ) : (
            <p style={{ color: 'red' }}>No existen datos</p>
        );

    const actualizarInfoUsuario = (values) => {
        let data = {
            first_name: values.nombre,
            last_name: values.apellido,
            birth_date: values.nacimiento.format('YYYY/MM/DD'),
            gender: values.genero,
        };
        setNuevosDatosUsuario(data);
    };

    const resetPasswordHandler = async () => {
        // se manda la nueva contraseña
        const params = {
            axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
            ...datosNewPassword,
        };
        try {
            const request = await clienteAxios.put(
                `/restore_password/${usuarioSeleccionado.id}`,
                params,
            );
            message.success(request.data.data);
        } catch (error) {
            message.error(error.response?.data.message || 'Ocurrió un error');
        }
        setOpenModalInfoUser(false);
    };

    const editInfoHandler = async () => {
        const data = {
            axiosBaseURL: urls.REACT_APP_BACKEND_URL_E,
            ...nuevosDatosUsuario,
        };
        try {
            const request = await clienteAxios.put(
                `/update_user_by_id/${usuarioSeleccionado.id}`,
                data,
            );
            message.success(request.data.data);
        } catch (error) {
            message.error(
                error.response?.data.message ||
                    'Hubo un error al editar la información',
            );
        }
        setOpenModalInfoUser(false);
        getDataUser();
    };

    const openModalInfo = () => {
        setOpenModalInfoUser(true);
        setEditarInfo(true);
    };

    useEffect(() => {
        if (editarInfo) {
            editInfoForm.setFieldsValue({
                nombre: infoUsuario?.first_name,
                apellido: infoUsuario?.last_name,
                nacimiento: moment(
                    moment(infoUsuario?.birth_date?.replace('Z', '')).format(
                        formatoFechaInput,
                    ),
                    formatoFechaInput,
                ),
                genero: infoUsuario?.gender,
            });
        }
    }, [editarInfo]);

    const openModalResetPass = () => {
        setOpenModalInfoUser(true);
        setEditarInfo(false);
    };

    const [toBlockUser, setToBlockUser] = useState({});

    const toBlockUserHandler = async () => {
        const { nombre, apellido, nacimiento, genero } =
            editInfoForm.getFieldsValue();
        const data = {
            first_name: nombre,
            last_name: apellido,
            birth_date: nacimiento.format('YYYY/MM/DD'),
            gender: genero,
            enabled:
                toBlockUser?.enabled === true ||
                toBlockUser?.enabled === undefined
                    ? false
                    : true,
            deleted:
                toBlockUser?.deleted === false ||
                toBlockUser?.deleted === undefined
                    ? true
                    : false,
        };
        setToBlockUser({
            enabled: data.enabled,
            deleted: data.deleted,
        });
        setNuevosDatosUsuario(data);
        setOpenModalInfoUser(false);
    };

    const blockBtnName =
        toBlockUser?.enabled === true || toBlockUser?.enabled === undefined
            ? 'Bloquear usuario'
            : 'Desbloquear usuario';

    const ModalUsuario = () => (
        <Modal
            title={editarInfo ? 'Editar información' : 'Restablecer Contraseña'}
            open={openModalInfoUser}
            footer={false}
            closable={false}>
            <>
                {editarInfo ? (
                    <>
                        <Form
                            layout="vertical"
                            form={editInfoForm}
                            onFinish={actualizarInfoUsuario}>
                            <Row
                                gutter={24}
                                className={clases.labelAdministrar}>
                                <Col span={12}>
                                    <Form.Item label="Nombre" name="nombre">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Apellido" name="apellido">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Fecha de Nacimiento"
                                        name="nacimiento">
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            format={'DD/MM/YYYY'}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Género" name="genero">
                                        <Select style={{ width: '100%' }}>
                                            <Select.Option value="F">
                                                Femenino
                                            </Select.Option>
                                            <Select.Option value="M">
                                                Masculino
                                            </Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}>
                                <Col>
                                    <Button
                                        danger
                                        type="link"
                                        onClick={toBlockUserHandler}>
                                        {blockBtnName}
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        style={{ marginRight: '10px' }}
                                        onClick={() =>
                                            setOpenModalInfoUser(false)
                                        }>
                                        Cancelar
                                    </Button>
                                    <Button type="primary" htmlType="submit">
                                        Aceptar
                                    </Button>
                                </Col>
                            </div>
                        </Form>
                    </>
                ) : (
                    <>
                        <div className={clases.alertaPass}>
                            <Alert
                                message="Estás por modificar la contraseña de este usuario. Hazle saber de este cambio"
                                type="info"
                                showIcon
                            />
                        </div>

                        <Form
                            name="basic"
                            form={resetPassForm}
                            layout="vertical">
                            <Form.Item
                                name="contraseña"
                                label="Contraseña"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Por favor ingresa tu nueva contraseña',
                                    },
                                ]}
                                hasFeedback>
                                <Input.Password />
                            </Form.Item>

                            <Form.Item
                                name="repetirContraseña"
                                label="Repetir contraseña"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Por favor repite tu nueva contraseña',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (
                                                !value ||
                                                getFieldValue('contraseña') ===
                                                    value
                                            ) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    'Las contraseñas no coinciden!',
                                                ),
                                            );
                                        },
                                    }),
                                ]}>
                                <Input.Password />
                            </Form.Item>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}>
                                <Button
                                    style={{ marginRight: '10px' }}
                                    onClick={() => setOpenModalInfoUser(false)}>
                                    Cancelar
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={dataNewPassword}>
                                    Aceptar
                                </Button>
                            </div>
                        </Form>
                    </>
                )}
            </>
        </Modal>
    );

    const getDataUser = async () => {
        setCargandoDatos(true);
        await guardarInfoUsuario();
        setCargandoDatos(false);
    };

    const validateUserIsPremium = (isPremium) => {
        return isPremium === true ? 'Sí' : 'No';
    };

    const isValidBoolean = (value) => {
        return [true, false].includes(value);
    };

    return (
        <Spin spinning={cargandoDatos}>
            <div className={clases.container}>
                {openModalInfoUser && ModalUsuario()}
                <Col span={10}>
                    <Card
                        title="Información de contacto"
                        headStyle={{ fontWeight: 'bold' }}
                        bordered={true}
                        className={clases.cards}>
                        <Row gutter={24}>
                            <Col span={12}>
                                <strong>Nombre</strong>
                                {infoUsuario?.first_name ? (
                                    <p>{infoUsuario?.first_name}</p>
                                ) : (
                                    <p style={{ color: 'red' }}>
                                        No existen datos
                                    </p>
                                )}
                            </Col>
                            <Col span={12}>
                                <strong>Apellido</strong>
                                {infoUsuario?.last_name ? (
                                    <p>{infoUsuario?.last_name}</p>
                                ) : (
                                    <p style={{ color: 'red' }}>
                                        No existen datos
                                    </p>
                                )}
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <strong>Número de celular</strong>
                                {infoUsuario?.phone ? (
                                    <p>{infoUsuario?.phone}</p>
                                ) : (
                                    <p style={{ color: 'red' }}>
                                        No existen datos
                                    </p>
                                )}
                            </Col>
                            <Col span={12}>
                                <strong>Fecha de nacimiento</strong>
                                {infoUsuario?.birth_date ? (
                                    <p>
                                        {dateDDMMYYYY(
                                            infoUsuario?.birth_date.replace(
                                                'Z',
                                                '',
                                            ),
                                        )}
                                    </p>
                                ) : (
                                    <p style={{ color: 'red' }}>
                                        No existen datos
                                    </p>
                                )}
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <strong>Género</strong>
                                {infoUsuario?.gender ? (
                                    <p>{generoUsuario()}</p>
                                ) : (
                                    <p style={{ color: 'red' }}>
                                        No existen datos
                                    </p>
                                )}
                            </Col>
                            <Col span={12}>
                                <strong>Tiene suscripción Monchis +</strong>
                                {isValidBoolean(infoUsuario?.is_premium) ? (
                                    <p>
                                        {validateUserIsPremium(
                                            infoUsuario?.is_premium,
                                        )}
                                    </p>
                                ) : (
                                    <p style={{ color: 'red' }}>
                                        No existen datos
                                    </p>
                                )}
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Button type="link" onClick={openModalInfo}>
                                Editar información
                            </Button>
                        </Row>
                    </Card>
                </Col>

                <Col span={13}>
                    <Card
                        title="Preferencias"
                        headStyle={{ fontWeight: 'bold' }}
                        bordered={true}
                        className={clases.cards}>
                        <Row gutter={24}>
                            <Col span={12}>
                                <strong>Notificaciones</strong>
                                {infoUsuario?.user_configuration
                                    ?.push_notifications ? (
                                    <p>
                                        {infoUsuario?.user_configuration
                                            .push_notifications && 'Activadas'}
                                    </p>
                                ) : (
                                    <p style={{ color: 'red' }}>
                                        No existen datos
                                    </p>
                                )}
                            </Col>
                            <Col span={12}>
                                <strong>Sonido</strong>
                                {infoUsuario?.user_configuration?.sound ? (
                                    <p>
                                        {infoUsuario?.user_configuration
                                            .sound && 'Activado'}
                                    </p>
                                ) : (
                                    <p style={{ color: 'red' }}>
                                        No existen datos
                                    </p>
                                )}
                            </Col>
                        </Row>
                    </Card>
                    <Card
                        title="Seguridad"
                        headStyle={{ fontWeight: 'bold' }}
                        bordered={true}
                        className={clases.cards}>
                        <Col>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <strong>Dirección de correo</strong>
                                    {infoUsuario?.email ? (
                                        <p>{infoUsuario?.email}</p>
                                    ) : (
                                        <p style={{ color: 'red' }}>
                                            No existen datos
                                        </p>
                                    )}
                                </Col>
                                <Col>
                                    <strong>Tipo de inicio de sesión</strong>
                                    {infoUsuario?.login_method ? (
                                        <p>
                                            {infoUsuario?.login_method
                                                .charAt(0)
                                                .toUpperCase() +
                                                infoUsuario?.login_method.slice(
                                                    1,
                                                )}
                                        </p>
                                    ) : (
                                        <p style={{ color: 'red' }}>
                                            No existen datos
                                        </p>
                                    )}
                                </Col>
                                {/* (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 */}
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <strong>Sistema operativo</strong>
                                    {infoUsuario?.user_device?.type ? (
                                        <p>{infoUsuario?.user_device.type}</p>
                                    ) : (
                                        <p style={{ color: 'red' }}>
                                            No existen datos
                                        </p>
                                    )}
                                </Col>
                                <Col>
                                    <strong>Último inicio de sesión</strong>
                                    {infoUsuario?.last_login ? (
                                        <p>
                                            {dateDMYyHora(
                                                infoUsuario?.last_login,
                                            )}
                                        </p>
                                    ) : (
                                        <p style={{ color: 'red' }}>
                                            No existen datos
                                        </p>
                                    )}
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <strong>Dispositivo</strong>
                                    {infoUsuario?.user_device?.model ? (
                                        <p>{infoUsuario?.user_device.model}</p>
                                    ) : (
                                        <p style={{ color: 'red' }}>
                                            No existen datos
                                        </p>
                                    )}
                                </Col>
                                <Col>
                                    <strong>
                                        Fecha de registro de usuario
                                    </strong>
                                    {infoUsuario?.created_at ? (
                                        <p>
                                            {dateDDMMYYYY(
                                                infoUsuario?.created_at,
                                            )}
                                        </p>
                                    ) : (
                                        <p style={{ color: 'red' }}>
                                            No existen datos
                                        </p>
                                    )}
                                </Col>
                            </Row>
                            {infoUsuario?.login_method === 'email' && (
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <strong>Contraseña</strong>
                                        <Row>
                                            <Button
                                                type="link"
                                                onClick={openModalResetPass}
                                                style={{ marginLeft: '-15px' }}>
                                                Restablecer contraseña
                                            </Button>
                                        </Row>
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Card>
                </Col>
            </div>
        </Spin>
    );
};

export default CuentaUsuario;
