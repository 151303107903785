import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import PagoOnlineContext from './PagoOnlineContext';
import PagoOnlineReducer, { initialState } from './PagoOnlineReducer';

import axios from '../../config/axios';

import {
    SELECCIONAR_ID_TABLA,
    TARJETA_ELIMINADA_EXITO,
    DESCARGA_TARJETA_EXITO,
    OBTENER_USUARIOS_ACTUALIZADOS,
    SELECCIONAR_PAGINA_ACTUAL,
    BUSCAR_USUARIO,
    USUARIOS_TOTALES,
} from '../../types';
import { message } from 'antd';

const PagoOnlineState = ({ children }) => {
    const [loadingTarjeta, setLoadingTarjeta] = useState(false);
    const [loadingTablaUsuarios, setLoadingTablaUsuarios] = useState(false);

    // useReducer con dispatch para ejecutar las funciones
    const [state, dispatch] = useReducer(PagoOnlineReducer, initialState);

    const descargarTarjetasBd = async (id) => {
        let params = {};
        params['user_id'] = id;
        setLoadingTarjeta(true);
        try {
            // obtener datos de las tarjetas
            const respuesta = await axios.get('/user_card_list', { params });
            const cardsList = respuesta?.data?.data?.user_cards || [];
            const cardsListFormatted = cardsList.map((item) => ({
                cardId: item.card_id,
                cardBrand: item.card_brand,
                cardMaskedNumber: item.card_masked_number,
                cardType: item.card_type,
                expirationDate: item.expiration_date,
                aliasToken: item.alias_token,
                origen: item.provider,
            }));
            dispatch({
                type: DESCARGA_TARJETA_EXITO,
                payload: cardsListFormatted,
            });
        } catch (error) {
            message.error(error?.response?.data?.message);
        }
        setLoadingTarjeta(false);
    };

    const seleccionarIdTabla = (id) => {
        dispatch({
            type: SELECCIONAR_ID_TABLA,
            payload: id[0],
        });
    };

    const obtenerTarjetaEliminar = async (id, alias, origen) => {
        const data = {};
        data['user_id'] = id;
        data['alias_token'] = alias;
        data['origin'] = origen.toUpperCase();
        try {
            await axios.post('/user_card_delete', data);
            dispatch({
                type: TARJETA_ELIMINADA_EXITO,
                payload: alias,
            });
            // cuando se elimina la tarjeta mostrar mensaje de exito
            message.success('Tarjeta eliminada correctamente');
        } catch (error) {
            message.error('Hubo un problema al eliminar la tarjeta');
        }
    };

    const seleccionarPageTabla = (page) => {
        dispatch({
            type: SELECCIONAR_PAGINA_ACTUAL,
            payload: page,
        });
    };

    const obtenerUsuarios = async (page, name, tipo) => {
        setLoadingTablaUsuarios(true);
        const params = {
            page: page,
            full_name: tipo === 'nombre' ? name : undefined,
            user_id: tipo === 'id' ? name : undefined,
            email: tipo === 'correo' ? name : undefined,
        };
        try {
            // obtener datos
            const response = await axios.get('/users_list', { params });
            // extraer lista de usuarios
            const users = response?.data?.data?.users;
            dispatch({
                type: USUARIOS_TOTALES,
                payload: response?.data?.data?.total_users,
            });
            let userDataArray = [];
            users.forEach((user) => {
                const userActualizado = {
                    idUser: user?.user_id,
                    nombreCompleto: user?.user_name,
                    correo: user?.email,
                };
                userDataArray.push(userActualizado);
            });

            dispatch(
                {
                    type: OBTENER_USUARIOS_ACTUALIZADOS,
                    payload: [...userDataArray],
                },
                { type: BUSCAR_USUARIO, payload: [...userDataArray] },
            );
        } catch (error) {
            message.error(
                'Hubo un problema al obtener los datos de los usuarios',
            );
        }
        setLoadingTablaUsuarios(false);
    };

    return (
        <PagoOnlineContext.Provider
            value={{
                tarjetas: state.tarjetas,
                idPersona: state.idPersona,
                usuarios: state.usuarios,
                paginaActual: state.paginaActual,
                totalUsuarios: state.totalUsuarios,
                loadingTablaUsuarios,
                loadingTarjeta,

                seleccionarIdTabla,
                descargarTarjetasBd,
                obtenerTarjetaEliminar,
                obtenerUsuarios,
                seleccionarPageTabla,
            }}>
            {children}
        </PagoOnlineContext.Provider>
    );
};

export default PagoOnlineState;

PagoOnlineState.propTypes = {
    children: PropTypes.node,
};
