import React, { useEffect, useState } from 'react';
import ABMLayout from '../../../../layouts/ABMLayout';
import classes from './ReporteOrdenes.module.css';
import {
    Button,
    Card,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    Row,
    Select,
    Table,
    Typography,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { formatoHora } from 'components/utils-components/Date/index';
import {
    formatoGuaranies,
    formatoNumeros,
} from '../../../utils-components/Number';
import { useHistory } from 'react-router-dom';
import useRouterQuery from 'hooks/useRouterQueries';
import FormItemSelectBranches from 'components/atoms/Form/Select/Sucursales/index';
import useOrdersReport from 'components/hooks/useOrdersReport';
import moment from 'moment';

const { Text } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

const ReporteOrdenes = () => {
    const { createQueryParams } = useRouterQuery();
    const history = useHistory();
    const [fechaDesde, setFechaDesde] = useState('');
    const [fechaHasta, setFechaHasta] = useState('');

    const [reporteCuponesForm] = Form.useForm();

    const {
        loadingOrders,
        franchiseList,
        getFranchises,
        getOrdersStates,
        orderStatesList,
        deliveryTypes,
        getDeliveryTypes,
        paymentTypes,
        getPaymentTypes,
        getOrdersList,
        ordersTable,
        summaryData,
    } = useOrdersReport();

    const ordersSummary = (
        <Card className={classes.summaryCard}>
            <Row className={classes.summary}>
                <Col className={classes.colsResumen}>
                    <Text strong className={classes.totalPedidos}>
                        {summaryData?.count
                            ? formatoNumeros(Number(summaryData?.count))
                            : '0'}
                    </Text>
                    <Text>pedidos</Text>
                </Col>
                <Divider type="vertical" className={classes.dividers} />
                <Col className={classes.colsResumen}>
                    <Text strong className={classes.totalBilling}>
                        {summaryData?.total
                            ? formatoGuaranies(Number(summaryData?.total))
                            : '0'}
                    </Text>
                    <Text>Total</Text>
                </Col>
            </Row>
        </Card>
    );

    useEffect(() => {
        getFranchises();
        getOrdersStates();
        getDeliveryTypes();
        getPaymentTypes();
    }, []);

    // FILTERS

    const rangoFechas = (valor) => {
        let fechadesde = null;
        let fechahasta = null;
        if (valor !== null) {
            if (valor.length > 0) {
                fechadesde = valor[0].format('MM-DD-YYYY');
                fechahasta = valor[1].format('MM-DD-YYYY');
            }
        }
        setFechaDesde(fechadesde);
        setFechaHasta(fechahasta);
    };

    const handleSubmit = (values) => {
        getOrdersList(values);
    };

    // TABLE

    const ordersReportTable = ordersTable?.rows?.map((item) => {
        return {
            nroOrden: item?.id,
            cliente: `${item?.user?.first_name} ${item?.user?.last_name}`,
            franquicia: item?.branch?.frsnchise?.name,
            sucursal: item?.branch?.name,
            conDriver: item?.driver_request ? item?.driver_request : '-',
            categoria: item?.branch?.frsnchise?.category,
            tipoEntrega: item?.delivery_type_name,
            costoOrden: formatoGuaranies(item?.total),
            precioFinal: formatoGuaranies(
                `${
                    parseFloat((item?.total || '0').replace(/,/g, '')) +
                    parseFloat((item?.delivery_price || '0').replace(/,/g, ''))
                }`,
            ),
            tipoDePago: item?.payment_type,
            fecha: moment(item?.confirmed_at).format('DD/MM/YYYY'),
            hora: formatoHora(item?.confirmed_at),
            order_id: item?.id,
            estado: item?.status,
        };
    });

    const columns = [
        {
            title: 'Nro. orden',
            dataIndex: 'nroOrden',
            key: 'nroOrden',
            fixed: 'left',
        },
        {
            title: 'Cliente',
            dataIndex: 'cliente',
            key: 'cliente',
            fixed: 'left',
        },
        {
            title: 'Franquicia',
            dataIndex: 'franquicia',
            key: 'franquicia',
        },
        {
            title: 'Sucursal',
            dataIndex: 'sucursal',
            key: 'sucursal',
        },
        {
            title: 'Con driver',
            dataIndex: 'conDriver',
            key: 'conDriver',
        },
        {
            title: 'Categoría',
            dataIndex: 'categoria',
            key: 'categoria',
        },
        {
            title: 'Tipo de entrega',
            dataIndex: 'tipoEntrega',
            key: 'tipoEntrega',
        },
        {
            title: 'Costo orden',
            dataIndex: 'costoOrden',
            key: 'costoOrden',
        },
        {
            title: 'Precio final',
            dataIndex: 'precioFinal',
            key: 'precioFinal',
        },
        {
            title: 'Tipo de pago',
            dataIndex: 'tipoDePago',
            key: 'tipoDePago',
        },
        {
            title: 'Fecha',
            dataIndex: 'fecha',
            key: 'fecha',
        },
        {
            title: 'Hora',
            dataIndex: 'hora',
            key: 'hora',
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            key: 'estado',
        },
        {
            title: 'Acciones',
            dataIndex: 'actions',
            key: 'actions',
            fixed: 'right',
            align: 'center',
            render: (_elem, data) => (
                <Button
                    shape="circle"
                    icon={<SearchOutlined />}
                    onClick={() => verOrdenBotonHandler(data)}
                />
            ),
        },
    ];

    // Detalle Transaccion - Boton Ver Orden
    const verOrdenBotonHandler = (item) => {
        const orderId = item?.order_id;
        const queryParams = createQueryParams({ order_id: orderId });
        history.push(
            `/reportes/ventas/detalle-transaccion/ver-orden${queryParams}`,
        );
    };

    const [franchiseSelectedId, setFranchiseSelectedId] = useState();

    const handleFranchiseChange = (value) => {
        setFranchiseSelectedId(value);
        setDisableBranchesSelect(false);
    };

    const [disableBranchesSelect, setDisableBranchesSelect] = useState(true);

    const franchiseOptions =
        franchiseList &&
        franchiseList.map((item) => (
            <Option key={item.key} value={item.id}>
                {item.name}
            </Option>
        ));

    return (
        <ABMLayout titulo="Reporte de órdenes">
            <Card>
                <Form
                    layout="vertical"
                    form={reporteCuponesForm}
                    onFinish={handleSubmit}>
                    <Row gutter={[16, 16]}>
                        <Col span={6}>
                            <Form.Item
                                name="dates"
                                label="Rango de fechas"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Este campo no puede ir vacío',
                                    },
                                ]}>
                                <RangePicker
                                    onChange={rangoFechas}
                                    className={classes.rangoFechas}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="franchiseId" label={'Franquicia'}>
                                <Select
                                    onChange={handleFranchiseChange}
                                    optionFilterProp="children"
                                    loading={loadingOrders}
                                    allowClear
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    defaultValue="Todos">
                                    {franchiseList &&
                                        franchiseList.map((item) => (
                                            <Option
                                                key={item.key}
                                                value={item.id}>
                                                {item.name}
                                            </Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <FormItemSelectBranches
                                formInstance={reporteCuponesForm}
                                franchiseId={franchiseSelectedId}
                                disabled={disableBranchesSelect}
                            />
                        </Col>
                        <Col span={4}>
                            <Form.Item name="orderState" label="Estado">
                                <Select allowClear defaultValue="Todos">
                                    {orderStatesList &&
                                        orderStatesList?.map((state) => (
                                            <Option
                                                key={state.id}
                                                value={state.id}>
                                                {state.name}
                                            </Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item
                                name="deliveryType"
                                label="Tipo de entrega">
                                <Select allowClear defaultValue="Todos">
                                    {deliveryTypes &&
                                        deliveryTypes?.map((type) => (
                                            <Option
                                                key={type.id}
                                                value={type.id}>
                                                {type.type_name}
                                            </Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name="paymentType" label="Tipo de pago">
                                <Select allowClear defaultValue="Todos">
                                    {paymentTypes &&
                                        paymentTypes?.map((type) => (
                                            <Option
                                                key={type.id}
                                                value={type.id}>
                                                {type.name}
                                            </Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={3}>
                            <Form.Item name="clientID" label="ID de cliente">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name="orderNumber" label="Nro. de Orden">
                                <Input />
                            </Form.Item>
                        </Col>
                        {/* Habilitar una vez que el back reciba el dato */}
                        {/* <Col span={3}>
                            <Form.Item
                                name="transactionNumber"
                                label="Nro. de Transacción">
                                <Input />
                            </Form.Item>
                        </Col> */}
                        <Col>
                            <Form.Item>
                                <Button
                                    className={classes.marginBotones}
                                    type="primary"
                                    disabled={!(fechaDesde && fechaHasta)}
                                    htmlType="submit">
                                    Buscar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {ordersSummary}
                <Row>
                    <Col span={24}>
                        <Table
                            dataSource={ordersReportTable}
                            columns={columns}
                            rowKey={columns.id}
                            pagination={{
                                pageSize: ordersTable?.pageSize,
                                total: ordersTable?.total,
                                showSizeChanger: false,
                                current: ordersTable?.page,
                            }}
                            size="small"
                            scroll={{
                                x: 1600,
                            }}
                            tableLayout="fixed"
                            loading={loadingOrders}
                        />
                    </Col>
                </Row>
            </Card>
        </ABMLayout>
    );
};

export default ReporteOrdenes;
