import { createSlice } from '@reduxjs/toolkit';
import {
    cantidadPaginasSupport,
    cantidadPaginasSupportPendientes,
    orders_states,
} from '../../constants';
import { franquiciasIntegracionesSupport } from '../../constants/franquiciasIntegracionesSupport';
import lodash from 'lodash';
import { formatoTablaOrdenes } from '../../hooks/support/useSupport';

const supportReducer = createSlice({
    name: 'support',
    initialState: {
        pedidos: {
            AGENDADOS: null,
            CANCELADOS: null,
            DELIVERYPICKUP: null,
            FINALIZADOS: null,
            PENDIENTES: null,
            PREPARANDO: null,
        },
        estadoBotonJedi: false, // Estado inicial del botón Enviar a Jedi
        totalBusqueda: 0,
        totalPendientes: 0,
        totalPreparando: 0,
        totalDeliveryPickup: 0,
        totalFinalizados: 0,
        totalCancelados: 0,
        totalAgendados: 0,

        paginaActualPendientes: 1,
        paginaActualPreparando: 1,
        paginaActualDeliveryPickup: 1,
        paginaActualFinalizados: 1,
        paginaActualCancelados: 1,
        paginaActualAgendados: 1,
        paginaActualBusqueda: 1,

        totalPaginasPendientes: 1,
        totalPaginasPreparando: 1,
        totalPaginasDeliveryPickup: 1,
        totalPaginasFinalizados: 1,
        totalPaginasCancelados: 1,
        totalPaginasAgendados: 1,
        totalPaginasBusqueda: 1,

        loading: true,

        hasPermissionsLocked: false,
        imprimirOrdenLoading: false,
        solicitarDriverLoading: false,
        cancelarOrdenLoading: false,
        cambiarEstadoLoading: false,
        revertirCancelacionLoading: false,
        verificarDireccionLoading: false,
        revertirPagoLoading: false,

        listaPerfiles: [],
        listaOrdenes: [],
        listaOrdenesBusqueda: [],
        listaMotivosCancelacion: [],
        ordenesSeleccionadas: [],
        listaOrdenesCambioEstado: [],

        ordenSeleccionada: null,
        isVerOrdenModalVisible: false,
        isSolicitarDriverModalVisible: false,
        isCancelarOrdenModalVisible: false,
        isCambiarEstadoModalVisible: false,
        isRevertirCancelacionModalVisible: false,
        isVerificarDireccionModalVisible: false,

        siguienteEstado: undefined,
        abrirDesdeVerOrden: false,

        isRevertirPagoModalVisible: false,

        isAceptarAgendadoModalVisible: false,
        isRechazarAgendadoModalVisible: false,
        aceptarAgendadoLoading: false,
        rechazarAgendadoLoading: false,

        esUsuarioComercio: true,

        permisoVerIntegraciones: false,
        obtenerPermisoIntegraciones: false,

        hasPermitionToChangeState: null,
        enviarIntegracionModalVisible: false,
        loadingIntegraciones: false,
        deshabilitarBotonIntegracion: false,
        PreviewJediModalVisible: false,
        listaProductosTableIntegracionJedi: [],
        enviarJediModalVisible: false,
    },
    reducers: {
        // Reducer para actualizar el estado del boton Enviar a Jedi
        actualizarEstadoBotonJedi: (state, action) => {
            state.estadoBotonJedi = action.payload;
        },

        updateOrders: (state, action) => {
            state.pedidos = action.payload.pedidos;
            state.totalPendientes = action.payload.totalPendientes;
            state.totalPreparando = action.payload.totalPreparando;
            state.totalDeliveryPickup = action.payload.totalDeliveryPickup;
            state.totalFinalizados = action.payload.totalFinalizados;
            state.totalCancelados = action.payload.totalCancelados;
            state.totalAgendados = action.payload.totalAgendados;
            state.totalPaginasPendientes =
                action.payload.totalPaginasPendientes;
            state.totalPaginasPreparando =
                action.payload.totalPaginasPreparando;
            state.totalPaginasDeliveryPickup =
                action.payload.totalPaginasDeliveryPickup;
            state.totalPaginasFinalizados =
                action.payload.totalPaginasFinalizados;
            state.totalPaginasCancelados =
                action.payload.totalPaginasCancelados;
            state.totalPaginasAgendados = action.payload.totalPaginasAgendados;
            state.paginaActualPendientes =
                action.payload.paginaActualPendientes;
            state.paginaActualPreparando =
                action.payload.paginaActualPreparando;
            state.paginaActualDeliveryPickup =
                action.payload.paginaActualDeliveryPickup;
            state.paginaActualFinalizados =
                action.payload.paginaActualFinalizados;
            state.paginaActualCancelados =
                action.payload.paginaActualCancelados;
            state.paginaActualAgendados = action.payload.paginaActualAgendados;
        },

        updateOrdersFromWS: (state, action) => {
            const orden = action.payload.newOrder;
            let newOrdersList = [];
            let totalPendientes = state.totalPendientes;
            let totalPreparando = state.totalPreparando;
            let totalDeliveryPickup = state.totalDeliveryPickup;
            let totalFinalizados = state.totalFinalizados;
            let totalCancelados = state.totalCancelados;
            let totalAgendados = state.totalAgendados;
            let totalPaginasPendientes = state.totalPaginasPendientes;
            let totalPaginasPreparando = state.totalPaginasPreparando;
            let totalPaginasDeliveryPickup = state.totalPaginasDeliveryPickup;
            let totalPaginasFinalizados = state.totalPaginasFinalizados;
            let totalPaginasCancelados = state.totalPaginasCancelados;
            let totalPaginasAgendados = state.totalPaginasAgendados;

            // Remove MC Donals orders that were sent to the integration system
            const confirmedOrderStateId = orders_states.find(
                (item) => item.name === 'confirmed',
            ).key;
            const isConfirmedOrder =
                orden?.ordenOriginal?.order_state_id === confirmedOrderStateId;
            const idMcDonalds = franquiciasIntegracionesSupport.find(
                (item) => item.franquicia === 'MC',
            ).idFranquicia;
            const isFromMcDonalsSentToIntegration =
                orden?.ordenOriginal?.franchise_id === idMcDonalds &&
                (orden?.ordenOriginal?.mcdonals_external_invoice_id ||
                    orden?.ordenOriginal?.sent_to_integration);

            // Flatten current order list
            let currentOrdersList = lodash.cloneDeep(state.pedidos);
            const currentOrdersListKeys = Object.keys(currentOrdersList);
            let currentOrdersListFlat = [];
            currentOrdersListKeys.forEach((stateName) => {
                currentOrdersList[stateName]?.forEach((order) => {
                    currentOrdersListFlat.push(lodash.cloneDeep(order));
                });
            });

            if (isConfirmedOrder && isFromMcDonalsSentToIntegration) {
                let listaOrdenesArray = currentOrdersListFlat;

                const numeroOrden = orden?.numeroOrden;
                listaOrdenesArray = listaOrdenesArray.filter(
                    (item) => item.numeroOrden !== numeroOrden,
                );

                newOrdersList = listaOrdenesArray;
            } else {
                const orderStateIdOriginal = currentOrdersListFlat?.find(
                    (item) => item?.numeroOrden === orden?.numeroOrden,
                )?.estadoOrden;
                const orderStateIdNuevo = orden?.estadoOrden;

                // Buscar la orden nueva en la lista de ordenes
                let ordenes = currentOrdersListFlat;
                const index = ordenes.findIndex((object) => {
                    return object.numeroOrden === orden?.numeroOrden;
                });

                // Contar ordenes del state id nuevo
                const cantidadOrdenesEstadoWS = ordenes?.filter(
                    (orden) => orden?.estadoOrden === orderStateIdNuevo,
                )?.length;

                if (index >= 0) {
                    // Ordenes existentes
                    delete ordenes[index];
                    ordenes.push(orden);

                    // Actualizar cantidad-estado orden original
                    switch (orderStateIdOriginal) {
                        case 3:
                            totalPendientes -= 1;
                            break;
                        case 4:
                            totalPreparando -= 1;
                            break;
                        case 5:
                            totalDeliveryPickup -= 1;
                            break;
                        case 9:
                            totalDeliveryPickup -= 1;
                            break;
                        case 6:
                            totalFinalizados -= 1;
                            break;
                        case 2:
                            totalCancelados -= 1;
                            break;
                        case 10:
                            totalAgendados -= 1;
                            break;
                        case 11:
                            totalAgendados -= 1;
                            break;
                        case 12:
                            totalAgendados -= 1;
                            break;
                        default:
                            break;
                    }
                } else if (
                    orderStateIdNuevo === 3 &&
                    cantidadOrdenesEstadoWS < cantidadPaginasSupportPendientes
                ) {
                    // Ordenes nuevas Pendientes
                    ordenes.push(orden);
                } else if (cantidadOrdenesEstadoWS < cantidadPaginasSupport) {
                    // Ordenes nuevas (Todos los estados excepto Pendiente)
                    ordenes.push(orden);
                }

                // Actualizar contador de ordenes del state id nuevo
                switch (orderStateIdNuevo) {
                    case 3:
                        totalPendientes += 1;
                        break;
                    case 4:
                        totalPreparando += 1;
                        break;
                    case 5:
                        totalDeliveryPickup += 1;
                        break;
                    case 9:
                        totalDeliveryPickup += 1;
                        break;
                    case 6:
                        totalFinalizados += 1;
                        break;
                    case 2:
                        totalCancelados += 1;
                        break;
                    case 10:
                        totalAgendados += 1;
                        break;
                    case 11:
                        totalAgendados += 1;
                        break;
                    case 12:
                        totalAgendados += 1;
                        break;

                    default:
                        break;
                }

                totalPaginasPendientes =
                    Math.ceil(
                        totalPendientes / cantidadPaginasSupportPendientes,
                    ) || 1;
                totalPaginasPreparando =
                    Math.ceil(totalPreparando / cantidadPaginasSupport) || 1;
                totalPaginasDeliveryPickup =
                    Math.ceil(totalDeliveryPickup / cantidadPaginasSupport) ||
                    1;
                totalPaginasFinalizados =
                    Math.ceil(totalFinalizados / cantidadPaginasSupport) || 1;
                totalPaginasCancelados =
                    Math.ceil(totalCancelados / cantidadPaginasSupport) || 1;
                totalPaginasAgendados =
                    Math.ceil(totalAgendados / cantidadPaginasSupport) || 1;

                newOrdersList = ordenes;
            }

            const formatedNewOrdersList = formatoTablaOrdenes(newOrdersList);

            let ordersToChangeState = Array.from(
                state.listaOrdenesCambioEstado,
            );
            let newOrdersToChangeState = ordersToChangeState.filter(
                (item) => item !== orden?.numeroOrden,
            );

            state.listaOrdenesCambioEstado = newOrdersToChangeState;
            const ordersToChangeStateIsEmpty =
                state.listaOrdenesCambioEstado.length === 0;

            if (ordersToChangeStateIsEmpty) {
                state.cambiarEstadoLoading = false;
                state.isCambiarEstadoModalVisible = false;
            }
            state.pedidos = formatedNewOrdersList;
            state.totalPendientes = totalPendientes;
            state.totalPreparando = totalPreparando;
            state.totalDeliveryPickup = totalDeliveryPickup;
            state.totalFinalizados = totalFinalizados;
            state.totalCancelados = totalCancelados;
            state.totalAgendados = totalAgendados;
            state.totalPaginasPendientes = totalPaginasPendientes;
            state.totalPaginasPreparando = totalPaginasPreparando;
            state.totalPaginasDeliveryPickup = totalPaginasDeliveryPickup;
            state.totalPaginasFinalizados = totalPaginasFinalizados;
            state.totalPaginasCancelados = totalPaginasCancelados;
            state.totalPaginasAgendados = totalPaginasAgendados;
        },

        removeOrdenSeleccionada: (state) => {
            state.ordenSeleccionada = undefined;
        },

        updateOrdersLoadMore: (state, action) => {
            state.pedidos = action.payload.pedidos;
            state[action.payload.currentPageStateName] =
                action.payload.currentPageValue;
        },

        loadingOrders: (state, action) => {
            state.loading = action.payload;
        },

        permissionsLocked: (state, action) => {
            state.hasPermissionsLocked = action.payload;
        },

        // CANCELAR ORDEN

        loadingCancelarOrden: (state, action) => {
            const abrirDesdeVerOrden = Boolean(
                action?.payload?.abrirDesdeVerOrden,
            );

            const isVerOrdenModalVisible = abrirDesdeVerOrden
                ? false
                : state.isVerOrdenModalVisible;

            state.isCancelarOrdenModalVisible = true;
            state.cancelarOrdenLoading = !state.cancelarOrdenLoading;
            state.isVerOrdenModalVisible = isVerOrdenModalVisible;
            state.abrirDesdeVerOrden = abrirDesdeVerOrden;
        },

        cancelOrder: (state, action) => {
            state.ordenSeleccionada = action.payload.ordenSeleccionada;
            state.listaMotivosCancelacion =
                action.payload.listaMotivosCancelacion;
            state.cancelarOrdenLoading = false;
        },

        cerrarModalCancelarOrder: (state, action) => {
            const abrirDesdeVerOrden = state.abrirDesdeVerOrden;
            let isVerOrdenModalVisible = abrirDesdeVerOrden
                ? true
                : state.isVerOrdenModalVisible;
            let ordenSeleccionada = abrirDesdeVerOrden
                ? state.ordenSeleccionada
                : undefined;

            const cerrarModales = Boolean(action?.payload?.cerrarModales);
            isVerOrdenModalVisible = cerrarModales
                ? false
                : isVerOrdenModalVisible;
            ordenSeleccionada = cerrarModales ? undefined : ordenSeleccionada;

            state.ordenSeleccionada = ordenSeleccionada;
            state.listaMotivosCancelacion = [];
            state.isCancelarOrdenModalVisible = false;
            state.cancelarOrdenLoading = false;
            state.isVerOrdenModalVisible = isVerOrdenModalVisible;
            state.abrirDesdeVerOrden = false;
        },

        // VER ORDEN

        verOrdenModalRD: (state, action) => {
            state.ordenSeleccionada = action.payload.ordenSeleccionada;
            state.isVerOrdenModalVisible = true;
        },

        cerrarOrdenModalRD: (state) => {
            state.ordenSeleccionada = undefined;
            state.isVerOrdenModalVisible = false;
        },

        toggleOrdenPrintLoading: (state) => {
            state.imprimirOrdenLoading = !state.imprimirOrdenLoading;
        },

        toggleSupportLoading: (state) => {
            state.loading = !state.loading;
        },

        cargarListaPerfiles: (state, action) => {
            state.listaPerfiles = action.payload;
            state.loading = false;
        },

        // DRIVERS

        requestDriver: (state, action) => {
            state.ordenSeleccionada = action.payload.ordenSeleccionada;
            state.isSolicitarDriverModalVisible = true;
        },

        loadingSolicitarDriver: (state) => {
            state.solicitarDriverLoading = !state.solicitarDriverLoading;
        },

        cerrarModalSolicitarDriver: (state) => {
            state.ordenSeleccionada = undefined;
            state.isSolicitarDriverModalVisible = false;
        },

        actualizarOrdenDriverSolicitado: (state) => {
            // TODO: Esto podria resolverse recibiendo la orden por WS
            const numeroOrden = state?.ordenSeleccionada?.id;
            const listaOrdenesArr = state?.listaOrdenes?.map((item) => ({
                ...item,
                permitirSolicitarDriver:
                    item?.numeroOrden === numeroOrden
                        ? true
                        : item?.permitirSolicitarDriver,
            }));

            let listaOrdenesBusqueda = state?.listaOrdenesBusqueda || [];
            const esBusqueda = Boolean(listaOrdenesBusqueda?.length);
            if (esBusqueda) {
                listaOrdenesBusqueda = listaOrdenesBusqueda?.map((item) => ({
                    ...item,
                    permitirSolicitarDriver:
                        item?.numeroOrden === numeroOrden
                            ? true
                            : item?.permitirSolicitarDriver,
                }));
            }

            state.listaOrdenes = listaOrdenesArr;
            state.listaOrdenesBusqueda = listaOrdenesBusqueda;
        },

        // CAMBIAR ESTADO

        cambiarEstadoReducer: (state, action) => {
            const abrirDesdeVerOrden = Boolean(
                action.payload.abrirDesdeVerOrden,
            );
            const isVerOrdenModalVisible = abrirDesdeVerOrden
                ? false
                : state.isVerOrdenModalVisible;

            state.ordenSeleccionada = action.payload.ordenSeleccionada;
            state.siguienteEstado = action.payload.siguienteEstado;
            state.isCambiarEstadoModalVisible = true;
            state.isVerOrdenModalVisible = isVerOrdenModalVisible;
            state.abrirDesdeVerOrden = abrirDesdeVerOrden;
        },

        actualizarListaEstados: (state, action) => {
            state.listaOrdenesCambioEstado.push(action.payload);
        },

        cambiarEstadoLoadingReducer: (state, action) => {
            if ('payload' in action) {
                state.cambiarEstadoLoading = action.payload;
            } else {
                state.cambiarEstadoLoading = !state.cambiarEstadoLoading;
            }
        },

        cerrarModalCambiarEstado: (state, action) => {
            const abrirDesdeVerOrden = state.abrirDesdeVerOrden;
            let isVerOrdenModalVisible = abrirDesdeVerOrden
                ? true
                : state.isVerOrdenModalVisible;
            let ordenSeleccionada = abrirDesdeVerOrden
                ? state.ordenSeleccionada
                : undefined;

            const cerrarModales = Boolean(action?.payload?.cerrarModales);
            isVerOrdenModalVisible = cerrarModales
                ? false
                : isVerOrdenModalVisible;
            ordenSeleccionada = cerrarModales ? undefined : ordenSeleccionada;

            state.ordenSeleccionada = ordenSeleccionada;
            state.isCambiarEstadoModalVisible = false;
            state.isVerOrdenModalVisible = isVerOrdenModalVisible;
            state.abrirDesdeVerOrden = false;
        },

        // ORDENES SELECCIONADAS

        abrirModalOrdenesSeleccionadas: (state, action) => {
            state.isCambiarEstadoModalVisible = true;
            state.ordenesSeleccionadas = action.payload.ordenesSeleccionadas;
            state.siguienteEstado = action.payload.siguienteEstado;
        },

        // REVERTIR CANCELACION

        revertirCancelacionRD: (state, action) => {
            state.ordenSeleccionada = action.payload.ordenSeleccionada;
            state.isRevertirCancelacionModalVisible = true;
        },

        cerrarModalRevertirCancelacionRD: (state) => {
            state.ordenSeleccionada = undefined;
            state.isRevertirCancelacionModalVisible = false;
        },

        loadingRevertirCancelacionRD: (state) => {
            state.revertirCancelacionLoading =
                !state.revertirCancelacionLoading;
        },

        // VERIFICAR DIRECCION

        verificarDireccionRD: (state) => {
            state.isVerificarDireccionModalVisible = true;
            state.isVerOrdenModalVisible = false;
        },

        cerrarModalVerificarDireccion: (state) => {
            state.isVerificarDireccionModalVisible = false;
            state.isVerOrdenModalVisible = true;
            state.verificarDireccionLoading = false;
        },

        loadingVerificarDireccion: (state) => {
            state.verificarDireccionLoading = !state.verificarDireccionLoading;
        },

        actualizarVerificarDireccionRD: (state, action) => {
            // TODO: Esto podria resolverse recibiendo la orden por WS
            const numeroOrden = state?.ordenSeleccionada?.id;
            const direccionVerificada = state?.ordenSeleccionada?.checked;
            const nuevosDatosForm = action.payload.datosForm;

            // Creamos un nuevo array que se convierte luego en "listaOrdenes"
            const listaOrdenesArr = state?.listaOrdenes?.map((item) =>
                item?.numeroOrden === numeroOrden
                    ? {
                          ...item,
                          ordenOriginal: {
                              ...item?.ordenOriginal,
                              address: {
                                  ...item?.ordenOriginal?.address,
                                  name: nuevosDatosForm?.name,
                                  street1: nuevosDatosForm?.street1,
                                  street2: nuevosDatosForm?.street2,
                                  number: nuevosDatosForm?.number,
                                  reference: nuevosDatosForm?.reference,
                                  latitude: nuevosDatosForm?.latitude,
                                  longitude: nuevosDatosForm?.longitude,
                                  checked:
                                      item?.ordenOriginal?.address?.checked ===
                                      direccionVerificada
                                          ? item?.ordenOriginal?.address
                                                ?.checked
                                          : true,
                              },
                          },
                      }
                    : { ...item },
            );
            const ordenSeleccionadaArr = listaOrdenesArr.filter(
                (item) => item.numeroOrden === numeroOrden,
            );
            const ordenSeleccionadaActual =
                ordenSeleccionadaArr.length === 1
                    ? ordenSeleccionadaArr[0]?.ordenOriginal
                    : state?.ordenSeleccionada;

            state.listaOrdenes = listaOrdenesArr;
            state.ordenSeleccionada = ordenSeleccionadaActual;
            state.verificarDireccionLoading = false;
            state.isVerificarDireccionModalVisible = false;
            state.isVerOrdenModalVisible = true;
        },

        // REVERTIR PAGOS
        actualizarOrdenRevertirPagoReducer: (state) => {
            // TODO: Esto podria resolverse recibiendo la orden por WS
            const numeroOrden = state?.ordenSeleccionada?.id;
            let listaOrdenesArr = state?.listaOrdenes?.map((orden) =>
                orden?.numeroOrden === numeroOrden
                    ? {
                          ...orden,
                          pagoRevertido: true,
                          ordenOriginal: {
                              ...orden?.ordenOriginal,
                              actions: {
                                  ...orden?.ordenOriginal?.actions,
                                  is_reversed: true,
                              },
                          },
                      }
                    : { ...orden },
            );

            let listaOrdenesBusqueda = state?.listaOrdenesBusqueda || [];
            const esBusqueda = Boolean(listaOrdenesBusqueda?.length);
            if (esBusqueda) {
                listaOrdenesBusqueda = listaOrdenesBusqueda?.map((orden) =>
                    orden?.numeroOrden === numeroOrden
                        ? {
                              ...orden,
                              pagoRevertido: true,
                              ordenOriginal: {
                                  ...orden?.ordenOriginal,
                                  actions: {
                                      ...orden?.ordenOriginal?.actions,
                                      is_reversed: true,
                                  },
                              },
                          }
                        : { ...orden },
                );
            }

            state.listaOrdenes = listaOrdenesArr;
            state.listaOrdenesBusqueda = listaOrdenesBusqueda;
        },

        revertirPagoReducer: (state, action) => {
            state.ordenSeleccionada = action.payload.ordenSeleccionada;
            state.isRevertirPagoModalVisible = true;
            state.revertirPagoOpenedInDetail = action.payload.vieneDelDetalle;
        },

        cerrarModalRevertirPago: (state) => {
            if (!state.revertirPagoOpenedInDetail) {
                state.ordenSeleccionada = undefined;
                state.revertirPagoOpenedInDetail = false;
            }
            state.isRevertirPagoModalVisible = false;
        },

        loadingRevertirPago: (state, action) => {
            const listaOrdenes =
                action?.payload?.listaOrdenes || state.listaOrdenes;

            state.revertirPagoLoading = !state.revertirPagoLoading;
            state.listaOrdenes = listaOrdenes;
        },

        // AGENDADOS

        abrirModalAceptarAgendado: (state, action) => {
            state.ordenSeleccionada = action.payload.ordenSeleccionada;
            state.isAceptarAgendadoModalVisible = true;
        },
        cerrarModalAceptarAgendado: (state) => {
            state.isAceptarAgendadoModalVisible = false;
            state.aceptarAgendadoLoading = false;
        },
        abrirModalRechazarAgendado: (state, action) => {
            state.ordenSeleccionada = action.payload.ordenSeleccionada;
            state.isRechazarAgendadoModalVisible = true;
        },
        cerrarModalRechazarAgendado: (state) => {
            state.isRechazarAgendadoModalVisible = false;
        },
        confirmarAceptarAgendadoLoading: (state) => {
            state.aceptarAgendadoLoading = !state.aceptarAgendadoLoading;
        },
        confirmarRechazarAgendadoLoading: (state) => {
            state.rechazarAgendadoLoading = !state.rechazarAgendadoLoading;
        },

        actualizarTipoUsuario: (state, action) => {
            state.esUsuarioComercio = action.payload.esUsuarioComercio;
        },

        // PANTALLA BÚSQUEDA

        obtenerOrdenesBusquedaReducer: (state, action) => {
            // Page params
            const totalRows = action.payload?.totalRows || 1;
            const rowsPerPage = action.payload?.rowsPerPage || 1;
            const totalPages = Math.ceil(totalRows / rowsPerPage);
            const currentPage = action.payload?.page || 1;

            // Prepare orders array
            const isNextPage = Boolean(
                currentPage > 1 && state?.listaOrdenesBusqueda?.length,
            );
            let ordersList = action.payload?.ordersList || [];
            ordersList = isNextPage
                ? [...state.listaOrdenesBusqueda, ...ordersList]
                : ordersList;
            state.listaOrdenesBusqueda = ordersList;
            state.totalBusqueda = totalRows;
            state.totalPaginasBusqueda = totalPages;
            state.paginaActualBusqueda = currentPage;
            state.loading = false;
        },

        vaciarTablaBusquedaReducer: (state) => {
            state.listaOrdenesBusqueda = [];
        },

        hasPermitionToChangeState: (state, action) => {
            state.hasPermitionToChangeState =
                action.payload.hasPermitionToChangeState;
        },

        franquiciaConIntegracion: (state, action) => {
            state.permisoVerIntegraciones = action.payload;
            state.obtenerPermisoIntegraciones = true;
        },
        // Integraciones

        abrirModalEnviarIntegracionReducer: (state, action) => {
            let isVerOrdenModalVisible = Boolean(action.payload?.esVerDetalle);
            let abrirDesdeVerOrden = false;
            if (isVerOrdenModalVisible) {
                isVerOrdenModalVisible = false;
                abrirDesdeVerOrden = true;
            }
            state.enviarIntegracionModalVisible = true;
            state.ordenSeleccionada = action.payload.ordenSeleccionada;
            state.isVerOrdenModalVisible = isVerOrdenModalVisible;
            state.abrirDesdeVerOrden = abrirDesdeVerOrden;
        },

        cerrarModalEnviarIntegracionReducer: (state, action) => {
            let isVerOrdenModalVisible = state.isVerOrdenModalVisible;
            let abrirDesdeVerOrden = state.abrirDesdeVerOrden;
            if (abrirDesdeVerOrden) {
                const cerrarVerOrden = Boolean(action.payload?.cerrarVerOrden);
                isVerOrdenModalVisible = !cerrarVerOrden;
                abrirDesdeVerOrden = false;
            }
            state.enviarIntegracionModalVisible = false;
            state.loadingIntegraciones = false;
            state.isVerOrdenModalVisible = isVerOrdenModalVisible;
            state.abrirDesdeVerOrden = abrirDesdeVerOrden;
        },

        loadingIntegracionesReducer: (state) => {
            state.loadingIntegraciones = !state.loadingIntegraciones;
        },

        actualizarOrdenIntegracionReducer: (state) => {
            // TODO: Esto podria resolverse recibiendo la orden por WS
            const numeroOrden = state?.ordenSeleccionada?.id;

            let listaOrdenesArr = state?.listaOrdenes?.map((orden) => {
                let nuevaOrden = orden;

                if (orden?.numeroOrden === numeroOrden) {
                    nuevaOrden = {
                        ...orden,
                        envioIntegracion: true,
                        ordenOriginal: {
                            ...orden.ordenOriginal,
                            sent_to_integration: true,
                        },
                    };
                }

                return nuevaOrden;
            });

            let listaOrdenesBusqueda = state?.listaOrdenesBusqueda || [];
            const esBusqueda = Boolean(listaOrdenesBusqueda?.length);
            if (esBusqueda) {
                listaOrdenesBusqueda = listaOrdenesBusqueda?.map((orden) => {
                    let nuevaOrden = orden;

                    if (orden?.numeroOrden === numeroOrden) {
                        nuevaOrden = {
                            ...orden,
                            envioIntegracion: true,
                            ordenOriginal: {
                                ...orden.ordenOriginal,
                                sent_to_integration: true,
                            },
                        };
                    }

                    return nuevaOrden;
                });
            }

            // Remove MC Donals orders that were sent to their integration system
            const confirmedOrderStateId = orders_states.find(
                (item) => item.name === 'confirmed',
            ).key;
            const idMcDonalds = franquiciasIntegracionesSupport.find(
                (item) => item.franquicia === 'MC',
            ).idFranquicia;
            listaOrdenesArr = listaOrdenesArr.filter((orden) => {
                const isOrderFromMc =
                    orden?.ordenOriginal?.franchise_id === idMcDonalds;
                const hasBeenSentToIntegration =
                    orden?.ordenOriginal?.mcdonals_external_invoice_id ||
                    orden?.ordenOriginal?.sent_to_integration;
                const isOrderConfirmed =
                    orden?.ordenOriginal?.order_state_id ===
                    confirmedOrderStateId;
                const isMcWithIntegrationAndConfirmed =
                    isOrderFromMc &&
                    hasBeenSentToIntegration &&
                    isOrderConfirmed;
                return !isMcWithIntegrationAndConfirmed;
            });

            state.listaOrdenes = listaOrdenesArr;
            state.listaOrdenesBusqueda = listaOrdenesBusqueda;
        },

        obtenerListaProductosIntegracionJediReducer: (state, action) => {
            state.listaProductosTableIntegracionJedi =
                action.payload.listaProductosTableIntegracionJedi;
            state.loadingIntegraciones = false;
        },

        abrirModalEnviarJediReducer: (state, action) => {
            let isVerOrdenModalVisible = Boolean(action.payload?.esVerDetalle);
            let abrirDesdeVerOrden = false;
            if (isVerOrdenModalVisible) {
                isVerOrdenModalVisible = false;
                abrirDesdeVerOrden = true;
            }

            state.PreviewJediModalVisible = true;
            state.ordenSeleccionada = action.payload.ordenSeleccionada;
            state.isVerOrdenModalVisible = isVerOrdenModalVisible;
            state.abrirDesdeVerOrden = abrirDesdeVerOrden;
        },

        cerrarModalEnviarJediReducer: (state, action) => {
            let isVerOrdenModalVisible = state.isVerOrdenModalVisible;
            let abrirDesdeVerOrden = state.abrirDesdeVerOrden;
            if (abrirDesdeVerOrden) {
                const cerrarVerOrden = Boolean(action.payload?.cerrarVerOrden);
                isVerOrdenModalVisible = !cerrarVerOrden;
                abrirDesdeVerOrden = false;
            }

            state.PreviewJediModalVisible = false;
            state.loadingIntegraciones = false;
            state.enviarJediModalVisible = false;
            state.isVerOrdenModalVisible = isVerOrdenModalVisible;
            state.abrirDesdeVerOrden = abrirDesdeVerOrden;
            state.listaProductosTableIntegracionJedi = [];
        },

        abrirModalConfirmacionJedi: (state, action) => {
            state.enviarJediModalVisible = true;
            state.ordenSeleccionada = action.payload.ordenSeleccionada;
            state.PreviewJediModalVisible = false;
        },

        cerrarModalConfirmacionJediReducer: (state, action) => {
            let isVerOrdenModalVisible = state.isVerOrdenModalVisible;
            let abrirDesdeVerOrden = state.abrirDesdeVerOrden;
            if (abrirDesdeVerOrden) {
                const cerrarVerOrden = Boolean(action.payload?.cerrarVerOrden);
                isVerOrdenModalVisible = !cerrarVerOrden;
                abrirDesdeVerOrden = false;
            }

            state.enviarJediModalVisible = false;
            state.isVerOrdenModalVisible = isVerOrdenModalVisible;
            state.abrirDesdeVerOrden = abrirDesdeVerOrden;
            state.listaProductosTableIntegracionJedi = [];
        },
    },
});

export const updateOrders = supportReducer.actions.updateOrders;
export const updateOrdersLoadMore = supportReducer.actions.updateOrdersLoadMore;
export const loadingOrders = supportReducer.actions.loadingOrders;
export const updateOrdersFromWS = supportReducer.actions.updateOrdersFromWS;
export const permissionsLocked = supportReducer.actions.permissionsLocked;

export const verOrdenModalRD = supportReducer.actions.verOrdenModalRD;
export const cerrarOrdenModalRD = supportReducer.actions.cerrarOrdenModalRD;
export const toggleOrdenPrintLoading =
    supportReducer.actions.toggleOrdenPrintLoading;
export const toggleSupportLoading = supportReducer.actions.toggleSupportLoading;
export const cargarListaPerfiles = supportReducer.actions.cargarListaPerfiles;
export const loadingCancelarOrden = supportReducer.actions.loadingCancelarOrden;
export const cancelOrder = supportReducer.actions.cancelOrder;
export const loadingSolicitarDriver =
    supportReducer.actions.loadingSolicitarDriver;
export const cerrarModalCancelarOrder =
    supportReducer.actions.cerrarModalCancelarOrder;
export const cerrarModalSolicitarDriver =
    supportReducer.actions.cerrarModalSolicitarDriver;
export const requestDriver = supportReducer.actions.requestDriver;
export const actualizarOrdenDriverSolicitado =
    supportReducer.actions.actualizarOrdenDriverSolicitado;
export const cambiarEstadoReducer = supportReducer.actions.cambiarEstadoReducer;
export const cambiarEstadoLoadingReducer =
    supportReducer.actions.cambiarEstadoLoadingReducer;
export const cerrarModalCambiarEstado =
    supportReducer.actions.cerrarModalCambiarEstado;
export const abrirModalOrdenesSeleccionadas =
    supportReducer.actions.abrirModalOrdenesSeleccionadas;
export const revertirCancelacionRD =
    supportReducer.actions.revertirCancelacionRD;
export const cerrarModalRevertirCancelacionRD =
    supportReducer.actions.cerrarModalRevertirCancelacionRD;
export const loadingRevertirCancelacionRD =
    supportReducer.actions.loadingRevertirCancelacionRD;
export const verificarDireccionRD = supportReducer.actions.verificarDireccionRD;
export const cerrarModalVerificarDireccion =
    supportReducer.actions.cerrarModalVerificarDireccion;
export const loadingVerificarDireccion =
    supportReducer.actions.loadingVerificarDireccion;
export const actualizarVerificarDireccionRD =
    supportReducer.actions.actualizarVerificarDireccionRD;
export const abrirModalAceptarAgendado =
    supportReducer.actions.abrirModalAceptarAgendado;
export const cerrarModalAceptarAgendado =
    supportReducer.actions.cerrarModalAceptarAgendado;
export const abrirModalRechazarAgendado =
    supportReducer.actions.abrirModalRechazarAgendado;
export const cerrarModalRechazarAgendado =
    supportReducer.actions.cerrarModalRechazarAgendado;
export const confirmarAceptarAgendadoLoading =
    supportReducer.actions.confirmarAceptarAgendadoLoading;
export const confirmarRechazarAgendadoLoading =
    supportReducer.actions.confirmarRechazarAgendadoLoading;
export const obtenerOrdenesBusquedaReducer =
    supportReducer.actions.obtenerOrdenesBusquedaReducer;
export const actualizarTipoUsuario =
    supportReducer.actions.actualizarTipoUsuario;
export const vaciarTablaBusquedaReducer =
    supportReducer.actions.vaciarTablaBusquedaReducer;
export const hasPermitionToChangeState =
    supportReducer.actions.hasPermitionToChangeState;
export const franquiciaConIntegracion =
    supportReducer.actions.franquiciaConIntegracion;
export const actualizarOrdenRevertirPagoReducer =
    supportReducer.actions.actualizarOrdenRevertirPagoReducer;
export const revertirPagoReducer = supportReducer.actions.revertirPagoReducer;
export const cerrarModalRevertirPago =
    supportReducer.actions.cerrarModalRevertirPago;
export const loadingRevertirPago = supportReducer.actions.loadingRevertirPago;
export const abrirModalEnviarIntegracionReducer =
    supportReducer.actions.abrirModalEnviarIntegracionReducer;
export const cerrarModalEnviarIntegracionReducer =
    supportReducer.actions.cerrarModalEnviarIntegracionReducer;
export const loadingIntegracionesReducer =
    supportReducer.actions.loadingIntegracionesReducer;
export const actualizarOrdenIntegracionReducer =
    supportReducer.actions.actualizarOrdenIntegracionReducer;
export const abrirModalEnviarJediReducer =
    supportReducer.actions.abrirModalEnviarJediReducer;
export const obtenerListaProductosIntegracionJediReducer =
    supportReducer.actions.obtenerListaProductosIntegracionJediReducer;
export const cerrarModalEnviarJediReducer =
    supportReducer.actions.cerrarModalEnviarJediReducer;
export const abrirModalConfirmacionJedi =
    supportReducer.actions.abrirModalConfirmacionJedi;
export const cerrarModalConfirmacionJediReducer =
    supportReducer.actions.cerrarModalConfirmacionJediReducer;
export const actualizarListaEstados =
    supportReducer.actions.actualizarListaEstados;
export const removeOrdenSeleccionada =
    supportReducer.actions.removeOrdenSeleccionada;
export const actualizarEstadoBotonJedi =
    supportReducer.actions.actualizarEstadoBotonJedi;
export default supportReducer.reducer;
